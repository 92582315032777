<template>
  <StationList />
</template>

<script>
import StationList from '../components/StationList.vue'

export default {
  name: 'Station',
  components: {
    StationList,
  }
}
</script>