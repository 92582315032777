<template>
  <div>
    <v-dialog
        v-model="show"
        max-width="500px"
    >
      <v-card>
        <v-card-title>
          <span class="headline">{{ formTitle }}</span>
        </v-card-title>

        <v-card-text>
          <v-alert
              type="error"
              v-if="error !== null"
              v-text="error"
          />

          <v-container>
            <v-row>
              <v-col>
                <v-text-field
                    v-model="editedItem.name"
                    label="Название"
                    :error-messages="editFormNameErrors"
                    counter="150"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-text v-if="loading">
          Выполняется запрос
          <v-progress-linear
              indeterminate
              color="blue"
              class="mb-0"
          ></v-progress-linear>
        </v-card-text>

        <v-card-actions v-if="!loading && !$v.editedItem.$invalid">
          <v-spacer></v-spacer>
          <v-btn
              color="blue darken-1"
              text
              @click="close"
          >
            Отмена
          </v-btn>
          <v-btn
              color="blue darken-1"
              text
              @click="save"
          >
            Сохранить
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import api from '../api/station.js'
import { required, minLength, maxLength } from 'vuelidate/lib/validators'

export default {
  name: 'StationEditDialog',
  data: () => ({
    show: false,
    loading: false,
    error: null,
    editedId: -1,
    editedItem: {
      name: '',
    },
    defaultItem: {
      name: '',
    },
  }),
  validations: {
    editedItem: {
      name: {
        required,
        minLength: minLength(5),
        maxLength: maxLength(150),
      }
    },
  },
  computed: {
    formTitle () {
      return this.editedId === -1 ? 'Новая станция' : 'Изменение станции с ID=' + this.editedId
    },
    editFormNameErrors () {
      let errors = []

      if (this.$v.editedItem.name.$invalid) {
        if (this.$v.editedItem.name.required) {
          errors.push('Название - обязательное поле');
        }

        if (this.$v.editedItem.name.minLength) {
          errors.push('Минимальная длина названия - ' + this.$v.editedItem.name.$params.minLength.min);
        }

        if (this.$v.editedItem.name.maxLength) {
          errors.push('Максимальная длина названия - ' +  + this.$v.editedItem.name.$params.maxLength.max);
        }
      }

      return errors;
    }
  },
  methods: {
    close () {
      this.show = false
      this.error = null
      this.loading = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedId = -1
      })
    },
    showNewDialog () {
      this.editedItem = Object.assign({}, this.defaultItem)
      this.editedId = -1
      this.error = null
      this.loading = false
      this.show = true
    },
    showEditDialog (item) {
      this.editedItem = Object.assign({}, item)
      this.editedId = item.id
      this.error = null
      this.loading = false
      this.show = true
    },
    save () {
      this.error = null
      this.$v.$touch();

      if (this.$v.editedItem.$invalid) {
        return;
      }

      this.loading = true

      if (this.editedId > -1) {
        api
            .updateItem(this.editedId, {name: this.editedItem.name})
            .then((response) => {
              this.close()
              this.$notify({
                type: 'info',
                text: 'Заправочная станция обновлена',
              })
              this.$emit('update', response.data.item)
            })
            .catch((reason) => {
              this.error = typeof reason === 'string' ? reason : 'Неизвестная ошибка'
            })
            .finally(() => this.loading = false)
      } else {
        api
            .createItem({name: this.editedItem.name})
            .then((response) => {
              this.close()
              this.$notify({
                type: 'info',
                text: 'Заправочная станция добавлена',
              })
              this.$emit('create', response.data.item)
            })
            .catch((reason) => {
              this.error = typeof reason === 'string' ? reason : 'Неизвестная ошибка'
            })
            .finally(() => this.loading = false)
      }
    },
  },
}
</script>